<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card title="Export All Form">
        <div class="row mt-2">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span class="mt-1 float-right">Nomor Surat</span>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-input class="" placeholder="Input nomor surat" v-model="nosurat"></a-input>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2 mb-3">
            <a-button class="float-right btn btn-outline-dark ml-1" @click="req('K', '4')">Koreksi Pengawas</a-button>
            <a-button class="float-right btn btn-outline-primary ml-1" @click="req('K', '3')">Koreksi KAP</a-button>
            <a-button class="float-right btn btn-outline-warning ml-1" @click="req('K', '2')">Koreksi</a-button>
            <a-button class="float-right btn btn-outline-success" @click="req('R', '0')">Rutin</a-button>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
export default {
  data() {
    return {
      nosurat: '',
    }
  },
  methods: {
    async req(s, status) {
      var nosurat = ''
      if (this.nosurat !== '') nosurat = '?NomorSurat=' + this.nosurat
      // var res =
      await lou.getZip('master/exportzip/' + s + '/' + status + nosurat)
    },
  },
}
</script>

<style>

</style>
